@media (min-width: 992px) {
  .layout-dashboard .ant-layout-footer {
    margin: 0 0 20px 20px;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    margin: 20px 0 0 20px;
    padding: 13px 15px;
    height: calc(100vh - 20px);
  }
  .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
    margin-left: 270px;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .layout-dashboard .ant-layout-header {
    margin: 0px 20px;
    /* margin-top: 15px; */
  }
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}

@media (min-width: 992px) {
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
}

@media (max-width: 768px) {
  .layout-dashboard.layout-dashboard-rtl .header-control {
    justify-content: flex-end;
  }
  .gips-duration-filter {
    flex-wrap: wrap;
  }
  .gips-duration-filter .ant-radio-group {
    width: 100%;
    margin-right: 0px !important;
    margin-bottom: 10px;
  }
  .gips-duration-filter label {
    width: auto;
  }
  .gips-duration-filter button {
    width: 100%;
  }
  .chart-visitor-btn {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .layout-dashboard.layout-dashboard-rtl .header-control {
    justify-content: flex-end;
  }
}
@media (max-width: 800px) {
  .gips-duration-filter {
    flex-wrap: wrap;
  }
  .gips-duration-filter .ant-radio-group {
    width: 100%;
    margin-right: 0px !important;
    margin-bottom: 10px;
  }
  .gips-duration-filter label {
    width: auto;
  }
  .gips-duration-filter button {
    width: 100%;
  }
  /* .chart-visitor-btn {
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  } */
}
@media (min-width: 1660px) {
  .trading-overview .ant-tabs-nav-list {
    width: 100% !important;
  }
}
@media (max-width: 500px) {
  .preview-date-filter {
    margin-bottom: 20px;
  }
  .equityGrowth {
    flex-direction: column;
    align-items: start;
  }
  .equityGrowthHeader {
    justify-content: space-between;
    width: 100%;
  }
  .trackRecodeProFilter {
    margin-right: 0 !important;
  }
  .trackRecord {
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }
  .account-detail-container {
    flex-wrap: wrap;
  }
  .account-detail-box {
    margin-left: 0;
  }
  .tradesHistoryHeader {
    flex-direction: column;
  }
  .rangeButton {
    width: 100%;
    margin: 10px 0;
  }
  .filterButton {
    margin-bottom: 10px;
  }
  .filterButton-mt {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .trade-history-radio-btn-res {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important;
  }
  .trade-history-radio-btn-res .ant-radio-button-wrapper {
    width: 100%;
    text-align: center;
  }
  .ant-picker-panel-layout {
    max-width: 300px;
    overflow: scroll;
  }
  .date-range-picker {
    margin: 10px 0;
  }
  .reportsTableFilterContent {
    max-height: 35vh !important;
  }
  .perVsBenChart {
    flex-wrap: wrap;
  }
}
